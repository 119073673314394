<template>
  <div class="userAgreement-container">
    <div class="title">
      用户服务协议
    </div>
    <div class="contents">
      <div class="before-tip">
        欢迎使用派森诺基因云平台！
        本协议系由派森诺生物科技股份有限公司GenesCloud云平台（以下简称GenesCloud）与使用GenesCloud服务的主体（包括但不限于个人、团队等，以下简称“用户”或“您”）对GenesCloud平台的使用及相关服务所订立的有效合约。
      </div>
      <div class="before-tip">
        请您在注册时务必仔细阅读并理解各项条款内容。GenesCloud有权在必要时候修改服务相关条款，如果用户不同意所改动的内容，可以主动取消获得的平台服务。如果用户继续享用平台服务，则视为接受本平台相关服务条款。GenesCloud保留随时修改或中断服务而不需要通知用户的权利。如果您对本协议的条款有疑问的，请通过派森诺相关业务部门进行询问，派森诺将向您解释条款内容。本协议适用于任何派森诺GenesCloud服务。
      </div>

      <div class="content-details">
        <p class="detail-title">一、用户使用权责</p>
        <div class="list">
          <p class="details-subtitle">1. 用户注册</p>
          <p class="details-list">
            在使用GenesCloud服务前，用户应填写真实有效的联系信息：联系方式、电子邮件地址，并设置密码等。您提供给GenesCloud的所有信息将受到GenesCloud的隐私条款的保护，您有责任在帐户个人信息设置页面中更新您的注册信息。
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">2. 账号使用</p>
          <p class="details-list">
            云帐号使用权仅供购买者本人使用。注册用户不得转让，转借，出租，出售，分享或以其它方式转让账号与密码给任何第三方使用。
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">3. 用户使用</p>
          <p class="details-list">
            若用户使用GenesCloud云服务的行为不符合本协议相关规定，GenesCloud在经由通知、举报等途径发现时有权做出独立判断，且有权在无需事先通知用户的情况下立即终止向用户提供部分或全部服务。
          </p>
          <p class="details-list">
            用户若通过GenesCloud云服务散布和传播反动、色情或其他违反国家法律、法规的信息，GenesCloud云服务的系统记录将可能作为用户违反法律法规的证据；因用户进行上述内容在GenesCloud云服务的上载、传播而导致任何第三方提出索赔要求或衍生的任何损害或损失，由用户自行承担全部责任。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">二、服务内容</p>
        <div class="list">
          <p class="details-subtitle">1. GenesCloud服务</p>
          <p class="details-list">
            GenesCloud云平台是一个通过技术服务向广大用户提供数据管理和分析的在线服务平台，服务本身对用户传输内容不做任何修改或编辑
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">2. 用户理解</p>
          <p class="details-list">
            GenesCloud可能会限制某些或所有用户在某段时间内访问或使用我们的部分服务，由于网站维护或计算机设备或其他原因，本网站和/或服务可能在某段时间内无法使用。
          </p>
          <p class="detail-list">
            GenesCloud服务仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。/或服务可能在某段时间内无法使用。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">三、所有权</p>
        <div class="list">
          <p>
            GenesCloud保留对以下各项内容、信息完全的、不可分割的所有权及知识产权
          </p>
          <p class="details-subtitle">1. 平台所有权</p>
          <p class="details-list">
            除用户自行上传、传播的内容外，GenesCloud云服务及其所有元素，包括但不限于所有内容、数据、技术、软件、代码、用户界面以及与其相关的任何衍生作品，用户信息。
            未经GenesCloud云同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。对于由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，GenesCloud不以任何形式向用户或任何第三方负法律、经济责任；
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">2. 软件所有权</p>
          <p class="details-list">
            GenesCloud云服务为提供分析服务而使用的任何软件（包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse
            engineer）、反向编译（decompile）或反汇编（disassemble），或以其他方式发现其原始编码，以及实施任何涉嫌侵害著作权的行为。
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">3. 商标</p>
          <p class="details-list">
            服务标志及商标GenesCloud，其中包括但不限于“GenesCloud”和GenesCloud标志，是由派森诺所有的服务商标。任何其他商标、服务标志、标识或出现在服务中的第三方商业名称均为其用户所有的财产，非经所有者的书面同意，用户不得复制或使用这些商标、标志或商品名称。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">四、数据权责</p>
        <div class="list">
          <p class="details-subtitle">1. 提交数据</p>
          <p class="details-list">
            用户可以在任何时候根据本服务条款提交数据给GenesCloud，但必须保证上述数据符合适用的法律法规，并遵守适用的法律法规。成功提交的数据需要进行处理，您授予GenesCloud非独家的汇总和提交数据权利。
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">2. 数据内容</p>
          <p class="details-list">
            用户不得滥用GenesCloud的服务，任何经由本服务以上传、张贴、发送即时信息、电子邮件或任何其他方式传送的资讯、资料、
            文字、软件、音乐、音讯、照片、图形、视讯、信息、用户的登记资料或其他资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者、使用者对其上传、使用行为自行承担责任。GenesCloud作为数据管理和分析的服务平台，无法控制经由本服务传送之内容，也无法对用户的使用行为进行全面控制，因此不能保证内容的合法性、正确性、完整性、真实性或品质；您已预知并同意将自行加以判断并承担所有风险，而不依赖于GenesCloud服务。
          </p>
        </div>

        <div class="list">
          <p class="details-subtitle">3. 数据监控</p>
          <p class="details-list">
            GenesCloud有权对用户使用GenesCloud网络服务的情况进行监督，如经由通知、举报等途径发现用户在使用GenesCloud服务所提供的网络服务时违反任何本协议的规定，GenesCloud有权要求用户改正或直接采取一切GenesCloud认为必要的措施（包括但不限于更改或删除用户上载的内容、暂停或终止用户使用网络服务的权利）以减轻用户不当行为造成的影响。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">五、承诺与保证</p>
        <p>用户保证，其在GenesCloud服务上传的内容不得并禁止直接或间接的：</p>
        <div class="list">
          <p class="details-list">
            1.
            利用GenesCloud云服务提供的网络服务上传、发布、传送或通过其他方式传播含有任何违反国家法律法规政策的信息、有害、威胁、辱骂、骚扰、诽谤、粗俗、攻击性、淫秽、色情、猥亵的内容，或其他GenesCloud认为的不良信息；
          </p>
          <p class="details-list">
            2.
            以任何方式干扰或企图干扰GenesCloud服务或GenesCloud网站任何部分或功能的正常运行
          </p>
          <p class="details-list">
            3. 提交您明知是虚假、误导、不实，或不准确的用户内容；
          </p>
          <p class="details-list">
            4.
            未经允许，进入计算机信息网络或者使用计算机信息网络资源的、对计算机信息网络功能进行删除、修改或者增加、存储、处理或者传输的数据和应用程序进行删除、修改或者增加的、故意破坏程序、危害计算机信息网络安全的行为；
          </p>
          <p class="details-list">
            5. 发表侵犯他人的著作权、商标或商品秘密的内容；
          </p>
          <p class="details-list">
            6.
            未事先经过原始用户的同意向任何非原始用户显示或以其他方式提供任何用户信息;
          </p>
          <p class="details-list">
            7.
            未经授权冒充他人或获取对GenesCloud服务的访问权；或者未经用户明确同意，让任何其他人亲自识别该用户；
          </p>
          <p class="details-list">
            8. 发布垃圾广告或非法宣传产品或服务；
          </p>
          <p class="details-list">
            9.
            宣传、教唆或参与任何多层次传销或传销、对通过服务获取的论坛发布恶意骚扰信息。
          </p>
          <p class="details-list">
            用户违反上述任何一款的保证，GenesCloud均有权就其情节，对其做出警告、屏蔽、直至取消资格的处罚；如因用户违反上述保证而给GenesCloud服务、GenesCloud服务用户或GenesCloud的任何合作伙伴造成损失，用户自行负责承担一切法律责任并赔偿损失。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">六、知识产权保护</p>
        <p>
          如果用户上传的内容允许其他用户下载、查看、收听或以其他方式访问或分发，其必须保证该内容的发布和相关行为实施符合相关知识产权法律法规中相关的版权政策，包括但不限于：
        </p>
        <div class="list">
          <p class="details-list">
            1.
            用户在收到侵权通知之时，应立即删除或禁止访问声明的侵权内容，并同时联系递送通知的人员以了解详细信息。
          </p>
          <p class="details-list">
            2.
            用户知悉并同意GenesCloud将根据相关法律法规对第三方发出的合格的侵权通知进行处理，并按照要求删除或禁止访问声明的侵权内容，采用并实施适当的政策，以期杜绝在相应条件下重复侵权。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">七、隐私保护</p>
        <p>
          GenesCloud充分尊重用户个人信息的保护，您在注册时向GenesCloud提供的所有信息将受GenesCloud隐私条款保护。GenesCloud不会公开或向第三方提供用户存储在GenesCloud云服务上的非公开内容，除非有下列情况：
        </p>
        <div class="list">
          <p class="details-list">
            1. 为维护GenesCloud的商标权、专利权及其他任何合法权益；
          </p>
          <p class="details-list">
            2. 有关法律、法规规定或GenesCloud云服务合法服务程序规定；
          </p>
          <p class="details-list">
            3. 其他依法需要公开、编辑或透露个人信息的情况。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">八、免责声明</p>
        <p>
          鉴于网络服务的特殊性，您理解在使用GenesCloud服务过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。如变更、中断或终止的网络服务属于免费网络服务，GenesCloud无需通知用户。出现上述情况时，GenesCloud将在第一时间内及时采取修复等措施，但由此造成的损失我们将在法律允许的范围内无需对任何用户或任何第三方承担任何责任。
        </p>
        <p>
          在法律允许的范围内，GenesCloud对以下情形导致的服务中断或受阻不承担责任，包括但不限于：
        </p>
        <div class="list">
          <p class="details-list">
            1.
            在GenesCloud开放测试期间可能会存在程序错误，如因此类情况造成用户数据丢失，GenesCloud服务无需为此承担任何责任。在开放测试期间，用户应自行将上传的数据进行妥善备份；
          </p>
          <p class="details-list">
            2.
            GenesCloud服务可在任何时候为任何原因变更本服务或删除其部分功能、可在任何时候取消或终止对用户的服务、并且取消或终止服务的决定不需要理由或通知用户，一旦服务取消，用户使用本服务的权利立即终止，
            用户在本服务中储存的任何信息可能无法恢复
          </p>
          <p class="details-list">
            3.
            GenesCloud服务需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，GenesCloud服务无需为此承担任何责任，但GenesCloud服务应尽可能事先进行通告；
          </p>
          <p class="details-list">
            4. 用户的电脑软件、系统、硬件和通信线路出现故障；
          </p>
          <p class="details-list">
            5. 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
          </p>
          <p class="details-list">
            6. 用户操作不当，或者用户通过非GenesCloud授权的方式使用本服务
          </p>
          <p class="details-list">
            7. 其他GenesCloud无法控制或合理预见的情形。
            您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，GenesCloud不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
          </p>
          <p class="details-list">
            1. 来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
          </p>
          <p class="details-list">
            2.
            因使用本服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
          </p>
          <p class="details-list">
            3.
            由于用户授权第三方（包括第三方应用）访问/使用其GenesCloud服务空间的内容所导致的纠纷或损失，应由用户自行负责，与GenesCloud无关；
          </p>
          <p class="details-list">
            4. 其他因网络信息或用户行为引起的风险。
            GenesCloud有权处理违法违规内容，但该权利不构成GenesCloud的义务或承诺，GenesCloud不保证及时发现违法行为或进行相应处理。
          </p>
        </div>
      </div>

      <div class="content-details">
        <p class="detail-title">九、其他</p>
        <div class="list">
          <p class="details-list">
            1. 本协议最终解释权归GenesCloud所有。
          </p>
          <p class="details-list">
            2.
            本协议一经公布即生效，GenesCloud有权随时对协议内容进行修改，修改后的结果公布于GenesCloud服务网站上。如果不同意GenesCloud服务对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受GenesCloud对本协议相关条款所做的修改。
          </p>
          <p class="details-list">
            3.
            GenesCloud服务对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人
          </p>
          <p class="details-list">
            3.
            GenesCloud服务需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，GenesCloud服务无需为此承担任何责任，但GenesCloud服务应尽可能事先进行通告；
          </p>
          <p class="details-list">
            4.
            本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向GenesCloud所在地的人民法院提起诉讼。
          </p>
          <p class="details-list">
            5.
            本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。
          </p>
          <p class="details-list">
            6.
            如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
